import {
    createApp
} from 'vue'
import App from '@/App.vue'
import Vuex from 'vuex'
import router from '@/router';
import Layout from "@/layout";

import {
    ElLoading,
    ElMessageBox
} from 'element-plus'
import {
    findDomainDetails,
    putLogin,
    loginOut,
    findMenuList,
    findMenuUserRelevancyList
} from "@/api/login"
import cookie from '../utils/cookie';
const app = createApp(App)
app.use(Vuex)

export default new Vuex.Store({

    state: {
        userInfo: null, // 用户基本信息
        domain: null, // 域名基本信息
        host_url: window.location.origin,
        loading: null, // loading 加载，
        ueditorMsg: '', // 检测文本
        defaultActiv: '', // 目录路径
        bangding: {},
        allmenu: [], // 全部菜单
        userMenu: [], // 用户菜单
        routes: [], // 缓存动态路由
        validityVisible: false,
        validityNumber:30
    },

    mutations: {
        /**设置用户信息 */
        setUserInfo(state, key) {
            if (key == null || key == 'null') {
                this.dispatch('verifyLogin')
            } else {
                localStorage.setItem('userInfo', key)
                if (JSON.parse(key).loginToken) {
                    cookie.setCookie('loginToken', JSON.parse(key).loginToken)
                }
                state.userInfo = JSON.parse(key);
            }
        },
        /** 设置全部菜单list */
        setAllMenu(state, key) {
            state.allmenu = key;
        },
        /** 设置用户菜单列表 */
        setUserMenu(state, key) {
            state.userMenu = key;
            localStorage.setItem('muenList', JSON.stringify(key))

        },
        /**初始化动态路由 */
        initRouters(state, key) {
            state.routes = key;
            localStorage.setItem('routes', JSON.stringify(key))
        },

        /**--------------------------------以下不知道是啥---------------------------------- */
        setBangding(state, key) {
            state.bangding = key

        },
        setDomain(state, key) {
            state.domain = key;
        },
        setLoading(state, key) {
            state.loading = key;
        },
        setUeditorMSG(state, key) {
            state.ueditorMsg = key;
        },
        setDefaultActiv(state, key) {
            state.defaultActiv = key;
        },

        setVisible(state, key) {
            state.validityVisible = key;
        },

        setVisibleNumber(state, key) {
            state.validityNumber = key;
        },
    },
    actions: {
        /**判断跳登录页面 { commit, state }*/
        verifyLogin() {
            router.push('/')
        },
        /**根据网址 获取基本信息 */
        getfindDomainDetailsFn({
            commit
        }) {
            findDomainDetails({
                http_url: this.state.host_url
                // 'https://www.ai-wgt.com'
            }).then(res => {
                commit('setDomain', res.domainNameResult)
                /**设置icon */
                var link =
                    document.querySelector("link[rel*='icon']") || document.createElement("link");
                link.type = "image/x-icon";
                link.rel = "shortcut icon";
                link.href = res.domainNameResult.domain_icon_url;
                document.getElementsByTagName("head")[0].appendChild(link);
            })
        },
        /**全局加载动画 */
        async openFullScreenFn({
            commit
        }, object) {
            let obj = object || {}
            let status = obj.status || false;
            let text = obj.text || '等待中';
            if (status) {
                this.state.loading = ElLoading.service({
                    lock: true,
                    text: text,
                    background: 'rgba(255, 255, 255, 0.32)',
                })
            } else {
                this.state.loading.close();
            }
        },
        /**ip插入 */
        putLoginFn({
            commit
        }, object) {
            putLogin(object)
        },
        /**退出登陆 */
        loginOutFn({
            commit
        }, text) {
            ElMessageBox.confirm(text == undefined ? '' : text + '您可以继续留在该页面，或者退出登录', '系统提示', {
                confirmButtonText: '退出登录',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                cookie.delCookie('loginToken');
                loginOut({
                    uid: this.state.userInfo.id
                }).then(res => {
                    cookie.delCookie('loginToken');
                    window.localStorage.clear();
                    this.dispatch('verifyLogin')
                })
            }).catch(() => {

            })

        },
        /**获取全部菜单 */
        findMenuList({
            commit
        }) {
            findMenuList({
                uid: this.state.userInfo.id,
            }).then(res => {
                commit('setAllMenu', res.menuManagementList)
                this.dispatch("findMenuUserRelevancyList");
            })


            // let list = [
            //     {
            //         "id": 6,
            //         "menu_parent_id": null,
            //         "menu_name": "AI灵感",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//78d0e654930940fc9c6bfdc30e5e1747.png",
            //         "menu_path_url": "ai/inspiration",
            //         "status": 1,
            //         "update_time": "2023-11-13T03:16:13.000+0000",
            //         "create_time": "2023-11-13T03:12:55.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//993d2b0e5a1c4904b90873d676a410c7.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 7,
            //         "menu_parent_id": 6,
            //         "menu_name": "AI创作",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "ai/write",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:15:25.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 8,
            //         "menu_parent_id": 6,
            //         "menu_name": "AI润色",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "ai/polish",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:22:25.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 10,
            //         "menu_parent_id": null,
            //         "menu_name": "文本校对",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//2a778275b11d4533b971f79bb30bde67.png",
            //         "menu_path_url": "proofread/text",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:27:02.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//4ad328b81d5f41f08aea8852a3da84cf.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 11,
            //         "menu_parent_id": null,
            //         "menu_name": "图片校对",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//2b6fbfa650db44cdbed96c7fcb7ceebb.png",
            //         "menu_path_url": "proofread/picture",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:28:13.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//a120ebc9381649669ee5699974729faf.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 12,
            //         "menu_parent_id": null,
            //         "menu_name": "文件列表",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//ed4529389dd84aa688645fd69a6a51a3.png",
            //         "menu_path_url": "file/list",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:29:44.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//90c5587979be42808807d4ca8c55b2d8.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 13,
            //         "menu_parent_id": null,
            //         "menu_name": "资料库",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//7ab11d730a1e407b9a48f94f6ef5dbc7.png",
            //         "menu_path_url": "data/bank",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:31:07.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//72934056de4f47dc8ce06058294b1030.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 14,
            //         "menu_parent_id": null,
            //         "menu_name": "公文模板",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//0743a61209b04797b9451cdf16ea79dc.png",
            //         "menu_path_url": "template/official",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:33:05.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//f1e07399bb464fa9912493d741aad015.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 15,
            //         "menu_parent_id": null,
            //         "menu_name": "自定义管理",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//4bcdbb2053674ba0ae82da6c2ea11c06.png",
            //         "menu_path_url": "custom/management",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:34:06.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//303f413da8114969b292325b198de8ac.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 16,
            //         "menu_parent_id": 15,
            //         "menu_name": "领导人词库",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "custom/leader",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:37:34.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 17,
            //         "menu_parent_id": 15,
            //         "menu_name": "报道顺序",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "custom/report",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:39:05.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 18,
            //         "menu_parent_id": 15,
            //         "menu_name": "正词",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "custom/correct",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:40:39.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 19,
            //         "menu_parent_id": 15,
            //         "menu_name": "错词",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "custom/wrong",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:42:56.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 20,
            //         "menu_parent_id": 15,
            //         "menu_name": "禁用词",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "custom/forbidden",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:43:35.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 21,
            //         "menu_parent_id": 15,
            //         "menu_name": "重点关注",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "custom/focus",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:44:42.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 22,
            //         "menu_parent_id": 15,
            //         "menu_name": "风险人员",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "custom/risk",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:45:41.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 23,
            //         "menu_parent_id": null,
            //         "menu_name": "办公工具",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//0ff3a4242e224118a940318a49e7d54d.png",
            //         "menu_path_url": "work/tools",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:47:17.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//a2b90074d4724fbaaa71067ee6acdda6.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 24,
            //         "menu_parent_id": 23,
            //         "menu_name": "文档转化",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/conversion",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:48:12.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 25,
            //         "menu_parent_id": 23,
            //         "menu_name": "语音听写",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/dictation",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:49:03.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 26,
            //         "menu_parent_id": 23,
            //         "menu_name": "水印添加",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/watermark",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:49:49.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 27,
            //         "menu_parent_id": 23,
            //         "menu_name": "繁简转换",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/HzConv",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:51:57.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 28,
            //         "menu_parent_id": 23,
            //         "menu_name": "中外互译",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/intertranslation",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:52:55.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 29,
            //         "menu_parent_id": 23,
            //         "menu_name": "词典查询",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/dictionary",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:53:49.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 30,
            //         "menu_parent_id": 23,
            //         "menu_name": "图片转PDF",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/veryPDF",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:54:38.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 31,
            //         "menu_parent_id": null,
            //         "menu_name": "媒体巡检",
            //         "menu_priority": 1,
            //         "menu_level": 1,
            //         "menu_icon_url": "https://file-network-test.icccall.com/icon//e6b3398654f54355addf3f8faf05ec61.png",
            //         "menu_path_url": "media/inspection",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:57:00.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": "https://file-network-test.icccall.com/icon//784eda5f78bf4d888e6b8e2ad40d7a53.png",
            //         "customize": null
            //     },
            //     {
            //         "id": 32,
            //         "menu_parent_id": 31,
            //         "menu_name": "巡检总览",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/overview",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:57:55.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 33,
            //         "menu_parent_id": 31,
            //         "menu_name": "特别关注",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/special",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:58:27.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 34,
            //         "menu_parent_id": 31,
            //         "menu_name": "内容更新",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/updateContent",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T03:59:25.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 35,
            //         "menu_parent_id": 31,
            //         "menu_name": "安全攻击",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/security",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T04:00:33.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 36,
            //         "menu_parent_id": 31,
            //         "menu_name": "访问异常",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/exception",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T04:01:14.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 37,
            //         "menu_parent_id": 31,
            //         "menu_name": "内容检测",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/contentDetecion",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T04:02:02.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 38,
            //         "menu_parent_id": 31,
            //         "menu_name": "附件检测",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/accessoryInspection",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T04:02:48.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 39,
            //         "menu_parent_id": 31,
            //         "menu_name": "巡检源管理",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/source",
            //         "status": 1,
            //         "update_time": "2023-11-17T01:24:14.000+0000",
            //         "create_time": "2023-11-13T04:03:25.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 40,
            //         "menu_parent_id": 31,
            //         "menu_name": "系统设置",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "media/systemSettings",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-13T04:04:07.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     },
            //     {
            //         "id": 41,
            //         "menu_parent_id": 23,
            //         "menu_name": "图片文字提取",
            //         "menu_priority": 1,
            //         "menu_level": 2,
            //         "menu_icon_url": null,
            //         "menu_path_url": "work/extraction",
            //         "status": 1,
            //         "update_time": null,
            //         "create_time": "2023-11-24T07:23:30.000+0000",
            //         "operation_uid": 2,
            //         "menu_icon_url_new": null,
            //         "customize": null
            //     }
            // ]

            // commit('setAllMenu', list)
            // this.dispatch("findMenuUserRelevancyList");
        },
        /**用户相关菜单信息 */
        findMenuUserRelevancyList({
            commit
        }) {
            findMenuUserRelevancyList({
                uid: this.state.userInfo.id,
                user_identity: this.state.userInfo.user_identity,
            }).then(res => {

                let catalogueList = res.menuUserRelevancy[0].catalogue_id.split(',');
                let menuList = [];
                catalogueList.forEach(a => {
                    this.state.allmenu.forEach(b => {
                        if (a == b.id) {
                            menuList.push(b)
                        }
                    })
                });
                let list = [];
                menuList.forEach((element) => {
                    if (!element.menu_parent_id) {
                        element.children = [];
                        list.push(element);
                    } else {
                        list.forEach((key) => {
                            if (key.id == element.menu_parent_id) {
                                key.children.push(element);
                            }
                        });
                    }
                });

                commit('setUserMenu', list)
                this.dispatch("setMuen");

            })
        },
        /**设置菜单 */
        setMuen({
            commit
        }) {
            let routerList = {
                path: "/",
                component: Layout,
                redirect: "/",
                children: [],
            }
            this.state.userMenu.forEach(item => {
                if (item.children.length > 0) {
                    item.children.forEach(key => {
                        routerList.children.push({
                            path: '/' + key.menu_path_url,
                            name: key.menu_path_url,
                            component: () => require.ensure([], (require) => require(`@/view/${key.menu_path_url}/index.vue`)),
                            meta: { title: key.menu_name }
                        })
                    })
                } else {
                    routerList.children.push({
                        path: '/' + item.menu_path_url,
                        name: item.menu_path_url,
                        component: () => require.ensure([], (require) => require(`@/view/${item.menu_path_url}/index.vue`)),
                        meta: { title: item.menu_name }
                    })
                }
            })
            router.addRoute(routerList);
            if (!localStorage.getItem('routerPath') || localStorage.getItem('routerPath') == "/") {
                router.push(routerList.children[0].path)
            } else {
                router.push(localStorage.getItem('routerPath'))
            }
            commit('initRouters', routerList)

        }
    }

})