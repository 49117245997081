<template>
  <div class="layout" ref="layout">
    <div class="layout-sidebar">
      <!-- 导航 -->
      <tSidebar ref="sidebar" @addMenuFloating="addMenuFloating"></tSidebar>
    </div>
    <div
      class="layout-content animation-All"
      :style="{ width: ' calc(100% - ' + paddingLeft + 'px)' }"
    >
      <!-- 左侧目录 -->
      <div class="layout-header">
        <tHeader ref="header" @setPaddingFn="setPaddingFn"></tHeader>
      </div>
      <!-- 常用工具 -->
      <div class="layout-tools">
        <tTools ref="tTools"></tTools>
      </div>
      <!-- 操作路由页面 -->
      <div class="layout-router">
        <div class="router-main">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tHeader from "./components/tHeader";
import tSidebar from "./components/tSidebar";
import tTools from "./components/tTools";
export default {
  name: "tLayout",
  components: {
    tHeader,
    tSidebar,
    tTools,
  },
  data() {
    return {
      paddingLeft: 240,
    };
  },

  mounted() {
    if (this.$domain.gdlt) {
      // 广东联通
      this.$refs.layout.classList.add("gdlt");
    } else if (this.$domain.yidong) {
      // 移动
      this.$refs.layout.classList.add("yidong");
    }
  },
  methods: {
    /**设置导航栏宽度 */
    setPaddingFn(val) {
      this.paddingLeft = val;
    },
    addMenuFloating(data) {
      this.$refs.tTools.addMenuFloating(data);
    },
  },
};
</script>

<style lang="less" scoped>
.layout {
  display: flex;

  .layout-content {
    width: calc(100% - 240px);
    .layout-header {
    }
  }
}
.layout-router {
  padding: 18px 10px 10px;
  box-sizing: border-box;
  .router-main {
    // background: #ffffff;
    height: calc(100vh - 186px);
  }
}

@media all and (max-width: 1600px) {
  .layout-router {
    .router-main {
      height: calc(100vh - 148px);
    }
  }
}
</style>