<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from "element-plus";
// 引入中文包
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
export default {
  name: "App",
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  setup() {
    const lang = () => {
      location.reload();
    };
    let locale = zhCn;
    return {
      locale,
    };
  },
  created() {
    if (localStorage.getItem("userInfo")) {
      this.$store.commit("setUserInfo", localStorage.getItem("userInfo"));
      this.$store.dispatch("findMenuList");
    }
  },
  mounted() {
    // console.log(process.env.VUE_APP_BASE_API);
  },
};
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  box-sizing: border-box;
  background: @colorf6f6f6;
}

@media all and (max-width: 1440px) {
 
}
</style>
