
import { createRouter, createWebHistory } from 'vue-router'
import Layout from "@/layout";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/', //换成''效果一样
            name: "index",
            component: () => require.ensure([], (require) => require('@/view/home/index/index.vue')),
            meta: { title: '首页' },
            hidden: true
        },
        {
            path: '/download/wps',
            name: "wps",
            component: () => require.ensure([], (require) => require('@/view/home/download/wps.vue')),
            meta: { title: 'wps插件' },
            hidden: true
        },
        {
            path: '/download/app',
            name: "app",
            component: () => require.ensure([], (require) => require('@/view/home/download/app.vue')),
            meta: { title: 'app下载' },
            hidden: true
        },
        {
            path: "/redirect", // 公共配置
            component: Layout,
            hidden: true,
            children: [
                {
                    path: "/redirect/:path(.*)",
                    component: (resolve) => require(["@/view/redirect"], resolve)
                },
            ]
        },
        {
            path: '/tbgz/examine',
            name: "tbgzExamine",
            component: () => require.ensure([], (require) => require('@/view/media/special/examine.vue')),
            meta: { title: '特别关注详情' },
            hidden: true
        },
        {
            path: '/nrgx/examine',
            name: "nrgxExamine",
            component: () => require.ensure([], (require) => require('@/view/media/updateContent/examine.vue')),
            meta: { title: '内容更新详情' },
            hidden: true
        },
        {
            path: '/nrjc/examine',
            name: "nrjcExamine",
            component: () => require.ensure([], (require) => require('@/view/media/contentDetecion/examine.vue')),
            meta: { title: '内容检测详情' },
            hidden: true
        },
        {
            path: '/fjjc/examine',
            name: "fjjcExamine",
            component: () => require.ensure([], (require) => require('@/view/media/accessoryInspection/examine.vue')),
            meta: { title: '附件检测详情' },
            hidden: true
        },
        {
            path: '/appDownload',
            name: "appDownload",
            component: () => require.ensure([], (require) => require('@/view/app/appDownload.vue')),
            meta: { title: 'APP下载' },
            hidden: true
        }

    ],
})
export default router;