<template>
  <!-- 侧边栏 -->
  <div class="sidebar animation-All" :style="{ width: width + 'px' }">
    <div class="sidebar-logo animation-All" v-if="$store.state.domain">
      <img :src="$store.state.domain.domain_logo_url_home" />
    </div>
    <div class="sidebar-logo animation-All" v-else>
      <img src="@/assets/image/logo/1.png" />
    </div>
    <div class="sidebar-box">
      <el-menu
        :default-active="$store.state.defaultActiv"
        class="menu-list"
        :collapse="isCollapse"
        @open="handleOpen"
        @close="handleClose"
        router
        @select="handleChange"
      >
        <temlate
          v-for="(item, index) in this.$store.state.userMenu"
          :key="index"
        >
          <el-sub-menu
            :index="'/' + item.menu_path_url"
            v-if="item.children.length > 0"
          >
            <template #title>
              <img class="item-img" :src="item.menu_icon_url" alt="" />
              <img
                class="item-img-active"
                :src="item.menu_icon_url_new"
                alt=""
              />
              <span class="item-text">{{ item.menu_name }}</span>
            </template>
            <el-menu-item
              :index="'/' + key.menu_path_url"
              v-for="(key, sup) in item.children"
              :key="sup"
              ><span class="sub-text">{{ key.menu_name }}</span></el-menu-item
            >
          </el-sub-menu>

          <el-menu-item
            :index="'/' + item.menu_path_url"
            v-if="item.children.length == 0"
            class="list-menu"
          >
            <img class="item-img" :src="item.menu_icon_url" alt="" />
            <img class="item-img-active" :src="item.menu_icon_url_new" alt="" />

            <span class="item-text">{{ item.menu_name }}</span>
          </el-menu-item>
        </temlate>
      </el-menu>
    </div>
  </div>
</template>
<script>
import {} from "@/api/login";
export default {
  name: "tSidebar",
  data() {
    return {
      width: 240,
    };
  },
  created() {
    this.userInfo = this.$store.state.userInfo;
    /**设置目录定位 */
    this.$store.commit("setDefaultActiv", this.$route.fullPath);
  },
  methods: {
    handleChange(e) {
      this.menuFn();
      let data = {};
      let menu = "";
      this.$store.state.userMenu.forEach((element) => {
        if ("/" + element.menu_path_url == e) {
          data = {
            user_id: this.$store.state.userInfo.id,
            floating_id: element.id,
            menu_icon_url: element.menu_icon_url,
            menu_path_url: e,
            menu_name: element.menu_name,
          };
        } else if (element.children.length > 0) {
          element.children.forEach((item) => {
            if ("/" + item.menu_path_url == e) {
              if (element.menu_name === "办公工具") {
                menu = element.menu_name;
              }
              data = {
                user_id: this.$store.state.userInfo.id,
                floating_id: item.id,
                menu_icon_url: element.menu_icon_url,
                menu_path_url: e,
                menu_name: item.menu_name,
              };
            }
          });
        }
      });
      if (menu === "办公工具") {
        this.$emit("addMenuFloating", data);
      }
    },
    menuFn() {
      this.$store.commit("setDefaultActiv", this.$route.path);
    },
  },
  watch: {
    "$store.state.defaultActiv": {
      handler: function () {
        this.defaultActiv = this.$store.state.defaultActiv;
      },
    },
  },
};
</script>
<style lang="less" scoped>
.sidebar {
  width: 280px;
  height: 100vh;
  background: #ffffff;
  box-sizing: border-box;
  overflow: hidden;
  //   z-index: 2000;
  //   left: 0;
  //   position: fixed;

  .sidebar-logo {
    text-align: center;
    padding: 30px 0;
    img {
      max-width: 176px;
      max-height: 48px;
    }
  }
  .sidebar-box::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .sidebar-box {
    padding: 0 22px 22px;
    box-sizing: border-box;
    height: calc(100vh - 112px);
    overflow: auto;

    /deep/.el-menu {
      border-right: none;
    }
    .menu-list {
      .item-img {
        width: 18px;
        height: 18px;
        display: block;
      }

      .item-img-active {
        width: 18px;
        height: 18px;
        display: block;
      }

      .item-text {
        margin-left: 14px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
      }

      .sub-text {
        margin-left: 14px;
        font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666;
      }
      .el-menu-item {
        height: 42px;
        line-height: 42px;
        margin-bottom: 5px;

        .item-img-active {
          display: none;
        }
        .item-img {
          display: block;
        }
      }
      .is-active.el-menu-item {
        .sub-text {
          color: #4e80ef;
        }
        .item-img-active {
          display: block;
        }
        .item-img {
          display: none;
        }
      }

      .list-menu.is-active.el-menu-item {
        // width: 236px;
        height: 42px;
        background: #4e80ef;
        border-radius: 8px;
        .item-text {
          color: #fff;
        }
        .item-img-active {
          display: block;
        }
        .item-img {
          display: none;
        }
      }

      .el-menu-item {
        padding-left: 44px;
      }
      /deep/.el-sub-menu__title {
        padding-left: 44px;
        line-height: 42px;
        height: 42px;
        margin-bottom: 5px;
        box-sizing: border-box;
        position: relative;
      }
      .el-sub-menu {
        .el-menu-item {
          padding-left: 62px;
        }
      }
      /deep/.is-active.el-sub-menu {
        .el-sub-menu__title {
          //   width: 236px;
          height: 42px;

          background: #4e80ef;
          border-radius: 8px;
          .item-text {
            color: #fff;
          }

          .item-img-active {
            display: block;
          }
          .item-img {
            display: none;
          }

          .el-icon {
            width: 12px;
            height: 12px;
            color: #fff;
          }
        }
      }
      /deep/.el-sub-menu {
        .el-sub-menu__title {
          .item-img-active {
            display: none;
          }
          .item-img {
            display: block;
          }
          .el-icon {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}
@media all and (max-width: 1600px) {
    .sidebar{
        .sidebar-box{
            .menu-list {
                .item-text{
                    font-size: 14px;
                }
                .sub-text {
                    font-size: 12px;
                }
            }
        } 
    } 
}
</style>
