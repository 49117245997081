<template>
  <!-- 音频转化 -->
  <div class="header-icon-list">
    <img
      v-if="this.$domain.yidong"
      src="../assets/svg/CD_yidong.svg"
      class="header-icon-img"
      :class="{ 'header-icon-animation': degBl }"
      @click="openAudioFn"
    />
    <img
      v-else
      src="../assets/svg/CD.svg"
      class="header-icon-img"
      :class="{ 'header-icon-animation': degBl }"
      @click="openAudioFn"
    />
    <div
      class="audio animation-All"
      :class="[audioBl ? 'openAudio' : 'closeAudio']"
      :style="{
        height: audioBl ? tableHeight + 122 + 'px' : '0px',
      }"
    >
      <el-card shadow="hover">
        <div class="flex-end">
          <div class="button-form animation-All" @click="closeFn">关闭</div>
        </div>
        <el-divider content-position="left">音频转化</el-divider>
        <el-table
          :data="list"
          style="width: 100%"
          border
          :height="tableHeight"
          v-loading="tableLoading"
        >
          <!-- 空数据状态的插槽 -->
          <template v-slot:empty>
            <el-empty description="暂无数据" />
          </template>
          <el-table-column type="index" align="center" label="序号" width="55">
          </el-table-column>
          <el-table-column align="center" label="预览" width="80">
            <template #default="scope">
              <img src="@/assets/svg/MP3.svg" style="width: 40px" alt="" />
            </template>
          </el-table-column>

          <el-table-column align="center" label="来源" width="250">
            <template #default="scope">
              <div class="font-14" v-html="scope.row.name"></div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="输出/转状态">
            <template #default="scope">
              <el-progress :percentage="scope.row.schedule"></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="180" align="center">
            <template #default="scope">
              <el-button
                size="small"
                v-if="scope.row.schedule == 100"
                plain
                @click="setContentFn(scope.row)"
                >点击查看
              </el-button>
              <el-button
                size="small"
                :loading="scope.row.loading"
                :disabled="scope.row.loading"
                v-else
                plain
                @click="miguMusicFn(scope.row, scope.$index)"
                >点击转化
              </el-button>
              <el-button
                size="small"
                :disabled="scope.row.loading"
                plain
                type="danger"
                @click="handleDelete(scope)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>
<script>
import { miguMusic } from "@/api/tools";
export default {
  name: "audioName",
  data() {
    return {
      list: [],
      tableHeight: 600,
      tableLoading: false,
      audioBl: false,
      /**是否旋转 */
      degBl: false,
    };
  },
  mounted() {
    window.addEventListener("resize", this.getHeight);
    this.getHeight();
    window.setListFn = this.setListFn;
    if (window.localStorage.getItem("AudioList")) {
      this.list = JSON.parse(window.localStorage.getItem("AudioList"));
    }
  },
  methods: {
    openAudioFn() {
      this.audioBl = !this.audioBl;
    },
    /**关闭 */
    closeFn() {
      this.audioBl = false;
    },
    getHeight() {
      // eslint-disable-next-line no-undef
      this.tableHeight = window.innerHeight - 550;
    },
    /**设置list */
    setListFn(data) {
      data.schedule = 0;
      data.content = "";
      data.loading = false;
      this.list.push(data);
      this.saveFn();
      this.openAudioFn();
    },
    /**开始转化 */
    miguMusicFn(data, index) {
      this.list[index].loading = true;

      /**开始转化 */
      miguMusic({
        filePath: data.real_path,
      })
        .then((res) => {
          this.list[index].loading = false;
          this.list[index].schedule = 100;
          this.list[index].content = res.data;
          this.saveFn();
        })
        .catch(() => {
          this.list[index].loading = false;
        });
    },
    /**保存本地list */
    saveFn() {
      window.localStorage.setItem("AudioList", JSON.stringify(this.list));
    },
    /**设置 */
    setContentFn(row) {
      this.$confirm("即将替换文本，是否继续", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "",
        type: "info",
      })
        .then(() => {
          window.getText(row.content);
          this.closeFn();
        })
        .catch(() => {});
    },
    /**删除 */
    handleDelete(row) {
      this.$confirm("此操作将删除此数据，是否继续?", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.list.splice(row.$index, 1);
          this.saveFn();
        })
        .catch(() => {});
    },
  },
  watch: {
    list: {
      handler(newName, oldName) {
        if (newName.length == 0) {
          this.degBl = false;
          return false;
        }
        let bl = false;
        for (let i = 0; i < newName.length; i++) {
          if (newName[i].loading == true) {
            bl = true;
          }
        }
        this.degBl = bl;
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.header-icon-list {
  margin-left: 10px;
  position: relative;
}
.audio {
  position: absolute;
  width: 800px;
  overflow: hidden;
  top: 20px;
  left: 40px;
  z-index: 1002;
}
.closeAudio {
  width: 0;
}
.openAudio {
  width: 800px;
}

.header-icon-img {
  width: 24px;
  display: block;
}

.header-icon-animation {
  animation: fadenum 6s linear infinite;
}
@keyframes fadenum {
  100% {
    transform: rotate(360deg);
  }
}
</style>