import request from '@/utils/request'

// 根据域名获取内容
export function findDomainDetails(data) { return request({ url: '/api/domain/findDomainDetails', method: 'post', data: data }) }

// 登录
export function pwdLogin(data) { return request({ url: '/api_user/pwdLogin', method: 'post', data: data }) }

// 退出登录
export function loginOut(data) { return request({ url: '/api_user/loginOut', method: 'post', data: data }) }

// 获取用户当前可登录的区域
export function searchRegion(data) { return request({ url: '/api_user/searchRegion ', method: 'post', data: data }) }

// ip地址查询
export function searchIP(data) { return request({ url: 'api_user/searchIP ', method: 'post', data: data }) }

// 获取验证码
export function sendSms(data) { return request({ url: '/api_user/sendSms', method: 'post', data: data }) }

// 手机号验证
export function yzCode(data) { return request({ url: '/api_user/yzCode', method: 'post', data: data }) }

// 密码修改
export function updateUserPwd(data) { return request({ url: '/api_user/updateUserPwd', method: 'post', data: data }) }

// 模拟登录 
export function adminPwdLogin(data) { return request({ url: '/api_user/adminPwdLogin', method: 'post', data: data }) }

// 登录获取验证码
export function sendErrorSms(data) { return request({ url: '/api_user/sendErrorSms ', method: 'post', data: data }) }

// IP 插入
export function putLogin(data) { return request({ url: '/api_filtering/putLogin', method: 'post', data: data }) }

// 直销插入 
export function addCustomer(data) { return request({ url: '/api_customer/addCustomer', method: 'post', data: data }) }

// 用户相关菜单信息 
export function findMenuUserRelevancyList(data) { return request({ url: '/api/relevancy/findMenuUserRelevancyList', method: 'post', data: data }) }

// 菜单信息 
export function findMenuList(data) { return request({ url: '/api/menu/findMenuList', method: 'post', data: data }) }
// 查询悬浮菜单 
export function findMenuFloatingList(data) { return request({ url: '/api/floating/findMenuFloatingList', method: 'post', data: data }) }
// 删除悬浮菜单 
export function deleteMenuFloating(data) { return request({ url: '/api/floating/deleteMenuFloating', method: 'post', data: data }) }
// 新增修改 
export function addMenuFloating(data) { return request({ url: '/api/floating/addMenuFloating', method: 'post', data: data }) }
/**试用账号 */
export function trialUserRegister(data) { return request({ url: '/api/trialUser/trialUserRegister', method: 'post', data: data }) }
/**试用账号验证码 */
// export function sendSms(data) { return request({ url: '/api_user/sendSms', method: 'post', data: data }) }

