<template>
  <div class="tools">
    <div class="tools-box">
      <div class="box-title">常用工具：</div>
      <div
        class="box-btn animation-All"
        v-for="(item, index) in toolsList"
        :key="index"
        @click="goPath(item)"
      >
        <div class="btn-icon">
          <img :src="item.menu_icon_url" alt="" />
        </div>
        <div class="btn-text animation-All">{{ item.menu_name }}</div>
        <div
          class="btn-colse"
          v-if="toolsList.length > 1"
          @click.stop="deleteMenuFloating(item)"
        >
          <img
            class="colse-img animation-All"
            src="@/assets/image/new/1.png"
            alt=""
          />
          <img
            class="colse-hover animation-All"
            src="@/assets/image/new/2.png"
            alt=""
          />
        </div>
      </div>
      <!-- <div class="box-add">
        <img
          class="add-img animation-All"
          src="@/assets/image/new/3.png"
          alt=""
        />
      </div> -->
    </div>
  </div>
</template>
<script>
import {
  findMenuFloatingList,
  deleteMenuFloating,
  addMenuFloating,
} from "@/api/login";
export default {
  name: "tTools",
  data() {
    return {
      toolsList: [],
    };
  },
  mounted() {
    this.findMenuFloatingList();
  },
  methods: {
    findMenuFloatingList() {
      findMenuFloatingList({
        user_id: this.$store.state.userInfo.id,
      }).then((res) => {
        this.toolsList = res.menuUserFloatingList;
        /**默认词典查询 */
        if (this.toolsList.length == 0) {
          this.addMenuFloating({
            floating_id: 29,
            menu_icon_url:
              "https://file-network-test.icccall.com/icon//0ff3a4242e224118a940318a49e7d54d.png",
            menu_name: "词典查询",
            menu_path_url: "/work/dictionary",
            user_id: this.$store.state.userInfo.id,
          });
        }
      });
    },
    deleteMenuFloating(item, data) {
      deleteMenuFloating({
        user_id: this.$store.state.userInfo.id,
        menu_user_floating_id: item.id,
      }).then(() => {
        if (data) {
          addMenuFloating(data).then(() => {
            this.findMenuFloatingList();
          });
        } else {
          this.findMenuFloatingList();
        }
      });
    },
    addMenuFloating(data) {
      if (this.toolsList.length > 3) {
        let menubl = true;
        this.toolsList.forEach((item) => {
          if (data.menu_name == item.menu_name) {
            menubl = false;
          }
        });
        if (menubl) {
          this.deleteMenuFloating(
            this.toolsList[this.toolsList.length - 1],
            data
          );
        } else {
          addMenuFloating(data).then(() => {
            this.findMenuFloatingList();
          });
        }
      } else {
        addMenuFloating(data).then(() => {
          this.findMenuFloatingList();
        });
      }
    },
    goPath(item) {
      this.$router.push(item.menu_path_url);
      this.$store.commit("setDefaultActiv", item.menu_path_url);
    },
  },
};
</script>
<style lang="less" scoped>
.tools {
  height: 68px;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  .tools-box {
    height: 68px;
    background: #fff;
    border-radius: 8px;
    width: calc(100% - 20px);
    padding: 0 30px 0 20px;
    box-sizing: border-box;
    margin-left: 10px;
    display: flex;
    .box-title {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 68px;
    }
    .box-btn {
      display: flex;
      height: 30px;
      background: #ffffff;
      border-radius: 4px;
      border: 1px solid #e4e7ed;
      box-sizing: border-box;
      line-height: 28px;
      margin-top: 19px;
      margin-left: 10px;
      padding-left: 15px;
      padding-right: 10px;
      cursor: pointer;
      .btn-icon {
        padding-top: 8px;
        img {
          width: 12px;
          height: 12px;
          display: block;
        }
      }
      .btn-text {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4d5464;
        padding: 0 8px;
      }
      .btn-colse {
        margin-top: 10px;
        width: 12px;
        position: relative;
        .colse-img {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 1;
        }
        .colse-hover {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
        }
        img {
          width: 8px;
          height: 8px;
          display: block;
        }
      }
    }
    .box-btn:hover .btn-text {
      color: #4e80ef;
    }
    .box-btn:hover .colse-img {
      opacity: 0;
    }
    .box-btn:hover .colse-hover {
      opacity: 1;
    }
    .box-add {
      width: 30px;
      height: 30px;
      background: rgba(78, 128, 239, 0.1);
      border-radius: 4px;
      margin-top: 19px;
      margin-left: 10px;
      position: relative;
      cursor: pointer;
      .add-img {
        width: 16px;
        height: 16px;
        position: absolute;
        display: block;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
@media all and (max-width: 1600px) {
  .tools {
    height: 48px;
    .tools-box {
      height: 48px;
      .box-title {
        line-height: 48px;
        font-size: 12px;
      }
      .box-btn {
        margin-top: 9px;
        .btn-text {
            font-size: 12px;
        }
      }
    }
  }
}
</style>