<template>
    <!-- 头部 :style="{ 'padding-left': paddingLeft + 'px' }"-->
    <div class="t-header flex-space-between animation-All">
        <div class="header-main flex-space-between animation-All">
            <div class="header-icon flex-start">
                <div class="icon-img animation-All" :class="{ 'icon-img-active': !openSidebar }" @click="handleSelect">
                    <img src="@/assets/image/new/7.png" alt="" />
                </div>
                <!-- 音频转化v-if="
            $store.state.userInfo.customer_level == 4 ||
            $store.state.userInfo.customer_level == 5 ||
            $store.state.userInfo.customer_level == 6 ||
            ($store.state.userInfo.customer_level == 7 && !$domain.suobei)
          " -->
                <audio-vue></audio-vue>
            </div>
            <div class="header-box flex-end" v-if="userInfo">
                <div class="box-line flex-start box-line-box" @click="wpsDow" v-if="isWps">
                    <div class="line-img">
                        <img src="@/assets/image/yidongHome/xiazai.jpg" alt="" />
                    </div>
                    <div class="line-html">WPS插件下载</div>
                </div>
                <div class="box-line flex-start">
                    <div class="line-icon">
                        <el-icon :size="17">
                            <UserFilled />
                        </el-icon>
                    </div>
                    <div class="line-text" v-html="userInfo.account"></div>
                </div>
                <div class="box-line flex-start">
                    <div class="line-icon">
                        <el-icon :size="17">
                            <Stopwatch />
                        </el-icon>
                    </div>
                    <div class="line-text">
                        <el-popover placement="bottom" title="信源详情" :width="500" trigger="click" :content="'微信：' +
                            userInfo.wechat_num +
                            '/' +
                            userInfo.wechat_total +
                            '；微博：' +
                            userInfo.weibo_num +
                            '/' +
                            userInfo.weibo_total +
                            '；网站：' +
                            userInfo.website_num +
                            '/' +
                            userInfo.website_total +
                            '；视频号：' +
                            userInfo.video_num +
                            '/' +
                            userInfo.video_total +
                            '；自媒体：' +
                            userInfo.media_num +
                            '/' +
                            userInfo.media_total
                            ">
                            <template #reference>
                                可用信源：{{
                                    userInfo.website_num +
                                    userInfo.wechat_num +
                                    userInfo.weibo_num +
                                    userInfo.video_num +
                                    userInfo.media_num
                                }}/{{ userInfo.total_source }}
                            </template>
                        </el-popover>
                    </div>
                </div>
                <div class="box-line flex-start" v-if="!$domain.gdlt_10010">
                    <div class="line-icon">
                        <el-icon :size="17">
                            <Timer />
                        </el-icon>
                    </div>

                    <div class="line-text">
                        到期：{{ $common.renderTime(userInfo.validity_time).split(" ")[0] }}
                    </div>
                </div>
                <el-popover placement="bottom" :width="200" trigger="click" @show="creatQrCode" @hide="creatQrCode_rem">
                    <template #reference>
                        <div class="box-line flex-start" v-if="!$domain.suobei">
                            <div class="line-icon">
                                <el-icon :size="17">
                                    <Download />
                                </el-icon>
                            </div>
                            <div class="line-text">APP下载</div>
                        </div>
                    </template>

                    <template #default>
                        <div class="app-download">
                            <div class="font-12 lineHeight2 align-center">
                                请使用安卓手机浏览器扫码安装
                            </div>
                            <qrcode-vue :value="value" :size="size" level="H" />
                        </div>
                    </template>
                </el-popover>
                <div class="box-line flex-start">
                    <div class="line-icon">
                        <el-icon :size="17">
                            <SwitchButton />
                        </el-icon>
                    </div>
                    <div class="line-text" @click="outLoginFn">退出系统</div>
                </div>
            </div>
        </div>
        <validityTime ref="validityTimeRef"></validityTime>
    </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
import audioVue from "@/components/audio.vue";
import { create, getImg } from "@/api/wxwarning";
import { findUserInfoDetails } from "@/api/media.js";
import validityTime from "./validityTime.vue";
export default {
    components: {
        QrcodeVue,
        audioVue,
        validityTime
    },
    name: "tHeader",
    data() {
        return {
            activeIndex: "0",
            openSidebar: true,
            paddingLeft: 240,
            userInfo: null,
            value: window.location.origin + "/appDownload",
            size: 165,
            pathUrl: null,
            isWps: false,
        };
    },
    watch: {
        "$store.state.userInfo": {
            handler: function () {
                this.userInfo = this.$store.state.userInfo;

            },
        },
    },
    created() {
        this.userInfo = this.$store.state.userInfo;
        if (!localStorage.getItem('timeVisible')) {
            this.checkTimeDifference();
        }
        this.$store.dispatch("getfindDomainDetailsFn");
        try {
            if (this.userInfo.host_url.indexOf(process.env.VUE_APP_BASE_WPS) != -1) {
                this.isWps = true;
            } else {
                this.isWps = false;
            }
        } catch (error) { }
    },
    methods: {
        handleSelect() {
            this.openSidebar = !this.openSidebar;
            if (this.openSidebar) {
                this.paddingLeft = 240;
                this.$emit("setPaddingFn", 240);
                this.$parent.$refs.sidebar.width = 240;
            } else {
                this.paddingLeft = 0;
                this.$emit("setPaddingFn", 0);
                this.$parent.$refs.sidebar.width = 0;
            }
        },
        outLoginFn() {
            this.$store.dispatch("loginOutFn");
        },
        /**获取二维码参数 */
        getCreateFn() {
            create({
                account: this.$store.state.userInfo.id,
            }).then((res) => {
                getImg({
                    ticket: JSON.parse(res.data).ticket,
                }).then((reson) => {
                    this.pathUrl = reson.data.pathUrl;
                });
            });
        },
        /**获取用户信息 */
        findUserInfoDetailsFn() {
            findUserInfoDetails({ uid: this.$store.state.userInfo.id }).then(
                (res) => {
                    let data = JSON.parse(res.userInfoDetails);
                    this.$store.commit("setBangding", {
                        binding_status: data.binding_status,
                        union_id: data.union_id,
                    });
                }
            );
        },
        wpsDow() {
            window.open(process.env.VUE_APP_BASE_WPS + "/wps/download");
        },
        /**判断是否不足30天 */
        checkTimeDifference() {
            const thirtyDaysInSeconds = 30 * 24 * 60 * 60;
            let currentTimestamp = Math.floor(Date.now() / 1000); // 当前时间戳，转换为秒
            const timeDifference = this.userInfo.validity_time / 1000 - currentTimestamp;
            let isLessThan30Days = timeDifference < thirtyDaysInSeconds;
            if (isLessThan30Days) {
                this.$store.commit("setVisible", true);
                this.$store.commit("setVisibleNumber", Math.floor(timeDifference / (24 * 60 * 60)));
                localStorage.setItem('timeVisible', 1)
            }
        }
    },
};
</script>
<style lang="less" scoped>
.t-header {
    height: 80px;
    width: 100%;
    box-sizing: border-box;
    //   position: fixed;
    //   left: 0;
    //   top: 0;
    //   z-index: 1999;

    .header-main {
        height: 80px;
        background: #fff;
        border-radius: 0px 0px 0px 9px;
        width: calc(100% - 10px);
        padding: 0 30px 0 20px;
        box-sizing: border-box;
        margin-left: 10px;
        line-height: 80px;

        .header-icon {
            cursor: pointer;
            padding: 28px 0;

            .icon-img {
                img {
                    width: 24px;
                    height: 24px;
                    display: block;
                }
            }

            .icon-img-active {
                transform-style: preserve-3d;
                transform: rotateY(180deg);
            }
        }

        .header-box {
            // line-height: 54px;

            .box-line {
                color: #333333;
                margin-left: 94px;

                .line-icon {
                    padding-top: 3px;
                }

                .line-text {
                    line-height: 80px;
                    padding-left: 12px;
                    font-size: 18px;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    cursor: pointer;
                }
            }
        }
    }
}

/**广东联通 */
.gdlt {
    .t-header {
        background: @colorfff;

        .header-logo {
            background: @colorfff;
        }

        .header-icon {
            padding-left: 20px;
            padding-top: 11px;
            cursor: pointer;

            .el-icon {
                color: @color424e59;
            }
        }

        .header-box {
            .box-line {
                color: @color424e59;
            }
        }
    }
}

/**移动 */
.yidong {
    .t-header {
        background: @colorfff;
        border-bottom: 2px solid @color81cfff;

        .header-icon {
            padding-left: 20px;
            padding-top: 11px;
            cursor: pointer;

            .el-icon {
                color: @color81cfff;
            }
        }

        .header-box {
            .box-line {
                color: @color81cfff;
            }
        }
    }
}

.app-download {
    canvas {
        margin: 0 auto;
        display: block;
    }
}

@media all and (max-width: 1600px) {
    .t-header {
        height: 60px;

        .header-main {
            height: 60px;
            line-height: 60px;

            .header-icon {
                padding: 19px 0;
            }
        }

        .header-main {
            .header-box {
                .box-line {
                    margin-left: 30px;

                    .line-text {
                        font-size: 14px;
                        line-height: 60px;
                    }
                }
            }
        }
    }
}
</style>
